export const chainNetList = [
  {
    chainId: '0x5',
    chainName: 'Goerli',
    nativeCurrency: {
      name: 'Goerli',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
];

export const deploymentChainList = [
  {
    id: 5,
    iconUrl: '/network-goerli-logo.svg',
    name: 'Goerli',
  },
  {
    id: 97,
    iconUrl: '/network-bsctestnet-logo.svg',
    name: 'Bsctestnet',
  },
  {
    id: 11155111,
    iconUrl: '/network-sepolia-logo.svg',
    name: 'Sepolia',
  },
  {
    id: 44787,
    iconUrl: '/network-alfajores-logo.svg',
    name: 'Alfajores',
  },
];
