import { observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

import { chainNetList } from './chainNet';
import web3Store from './Web3';

const { localStorage } = globalThis;

export class MetaMaskModel extends BaseModel {
  @observable provider = globalThis.window?.ethereum;

  @observable account = this.provider?.selectedAddress;

  constructor() {
    super();

    this.provider?.on('accountsChanged', accounts =>
      this.changeAccounts(accounts as string[]),
    );

    this.provider?.on('chainChanged', this.checkDefaultChain);

    this.provider?.on('connect', this.checkDefaultChain);
  }

  changeAccounts(accounts: string[]) {
    localStorage.account = this.account = accounts[0];
    web3Store.localStorageAccount = accounts;
    location.reload();
  }

  @toggle('downloading')
  async checkChain(chainId: string) {
    try {
      await this.provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        await this.provider?.request({
          method: 'wallet_addEthereumChain',
          params: chainNetList,
        });
      }
    }

    return chainNetList.some(
      ({ chainId }) => chainId === this.provider?.chainId,
    );
  }

  checkDefaultChain = () =>
    this.account && this.checkChain(chainNetList[0].chainId);
}
