import { BigNumber, constants } from 'ethers';
import { getAddress } from 'ethers/lib/utils';
import { HTTPError } from 'koajax';
import { observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

import { Base, zkcServerClient } from './Base';

export interface WalletOutput
  extends Base,
    Partial<
      Record<'totalProofSubmitted' | 'appUsedInTotal', number> &
        Record<'zkcBalance' | 'firstProofSubmittedDate' | 'nameTag', string>
    > {
  address: string;
}

export class WalletModel extends BaseModel {
  client = zkcServerClient;
  baseURI = 'wallet';

  @observable zkcrossAccount = constants.Zero;

  @toggle('downloading')
  async getOne(address: string) {
    try {
      const { body } = await this.client.get<WalletOutput>(
        `${this.baseURI}/${getAddress(address)}`,
      );

      return (this.zkcrossAccount = BigNumber.from(body!.zkcBalance || '0'));
    } catch (error) {
      const { status } = error as HTTPError;

      if (status !== 404) throw error;

      return (this.zkcrossAccount = constants.Zero);
    }
  }
}

export default new WalletModel();
