import { computed, observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';
import { withZKCWeb3MetaMaskProvider } from 'zkc-sdk';

import { MetaMaskModel } from './MetaMask';

const { localStorage } = globalThis;

export class Web3Model extends BaseModel {
  @observable metaMaskStore = new MetaMaskModel();

  @observable localStorageAccount = localStorage?.account;

  @computed
  get account() {
    return this.metaMaskStore.account;
  }

  @computed
  get provider() {
    return this.metaMaskStore.provider;
  }

  @computed
  get isLogin() {
    return !!this.account && this.account === this.localStorageAccount;
  }

  @toggle('uploading')
  connect() {
    return withZKCWeb3MetaMaskProvider(async provider => {
      const [account] = await provider.connect();

      return (this.localStorageAccount =
        localStorage.account =
        this.metaMaskStore.account =
          account);
    });
  }

  @toggle('downloading')
  async getCurrentAccount() {
    if (!this.provider?.isMetaMask) return;

    const accounts = await this.provider?.request<string[]>({
      method: 'eth_accounts',
    });

    if (!accounts?.[0]) return (localStorage.account = '');

    return (this.metaMaskStore.account =
      accounts[0] === localStorage.account ? accounts[0]! : null);
  }
}

export default new Web3Model();
