import classnames from 'classnames';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Container, Image, Nav, Navbar, Offcanvas } from 'react-bootstrap';

import { SITE_NAME } from '../../models/Env';
import { i18n } from '../../models/Translation';
import styles from './index.module.less';

const UserMenu = dynamic(() => import('./UserMenu'), { ssr: false });

const { t } = i18n;

export const MainNavigator: FC = observer(() => {
  const menu = [
    { href: '/', title: t('dashboard') },
    { href: '/create-app', title: t('create_app') },
  ];

  const { pathname } = useRouter();

  return (
    <Navbar
      className={`p-0 shadow ${styles.mainNavigator}`}
      bg="white"
      variant="light"
      fixed="top"
      expand="lg"
      collapseOnSelect
    >
      <Container className="d-flex justify-content-between align-items-centers h-100">
        <Navbar.Brand className={`d-flex py-0 ${styles.navbarBrand}`} href="/">
          <Image
            className="d-inline-block align-top"
            style={{ width: 106, height: 22 }}
            alt={SITE_NAME}
            src="/logo-dark.svg"
          />
          <div
            className={`ms-2 pb-1 d-flex justify-content-between align-items-center fw-bold ${styles.webTitle}`}
          >
            {t('dev')}
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-inner" />

        <Navbar.Offcanvas
          className={`justify-content-center ${styles.navbar}`}
          placement="end"
        >
          <Offcanvas.Body
            className={`d-flex flex-column-reverse flex-lg-row justify-content-end justify-content-lg-between align-items-lg-center h-100  ${styles.offcanvasBody}`}
          >
            <div className="flex-grow-1 d-lg-flex justify-content-end align-items-lg-center h-100">
              <Nav className="flex-shrink-0 align-items-lg-center h-100">
                {menu.map(({ href, title }) => (
                  <Link href={href} key={title} passHref>
                    <Nav.Link
                      className={classnames(
                        'd-flex align-items-lg-center fw-medium font-size-14',
                        { active: href === pathname },
                        styles.navLink,
                      )}
                    >
                      {title}
                    </Nav.Link>
                  </Link>
                ))}
              </Nav>
            </div>
            <UserMenu />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
});
