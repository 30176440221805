import discordAlt from '@iconify/icons-bxl/discord-alt';
import githubIcon from '@iconify/icons-bxl/github';
import mediumIcon from '@iconify/icons-bxl/medium';
import telegramIcon from '@iconify/icons-bxl/telegram';
import twitterIcon from '@iconify/icons-bxl/twitter';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Container, Image } from 'react-bootstrap';

import { urlConfig } from '../../models/Config';
import { i18n } from '../../models/Translation';
import styles from './index.module.less';

const socialList = [
  { icon: twitterIcon, to: urlConfig.twitter },
  { icon: telegramIcon, to: urlConfig.telegram },
  { icon: discordAlt, to: urlConfig.discord },
  { icon: mediumIcon, to: urlConfig.blog },
  { icon: githubIcon, to: urlConfig.github },
];

const { t } = i18n;

export const Footer: FC = observer(() => {
  const links = [
    {
      title: t('company'),
      items: [
        { label: t('about_us'), to: urlConfig.officialWebsite },
        // { label: t('terms_of_service'), to: '#' },
      ],
    },
    {
      title: t('development'),
      items: [
        // { label: t('api_documentation'), to: '#' },
        { label: t('knowledge_base'), to: urlConfig.document },
      ],
    },
  ];

  return (
    <footer className={`py-4 ${styles.footer}`}>
      <Container>
        <div className="d-flex justify-content-start">
          <Image src="/logo-light.svg" alt="ZKC Logo" />
        </div>

        <div className="d-flex flex-column flex-md-row-reverse justify-content-between text-white">
          <ul className="list-unstyled d-flex justify-content-start justify-content-md-end my-2 my-md-0">
            {links.map(({ title, items }, index) => (
              <li className={classNames({ 'ms-5': index })} key={title}>
                <h3 className="h6 fw-semibold mb-4">{title}</h3>
                <ul className="list-unstyled">
                  {items.map(({ label, to }) => (
                    <li key={label}>
                      <a
                        className="text-decoration-underline"
                        href={to}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>

          <div className="d-flex flex-column justify-content-end">
            <ul className={`list-unstyled d-flex mb-2 ${styles.socialList}`}>
              {socialList.map(({ icon, to }) => (
                <li className="me-2 rounded-circle" key={to}>
                  <a
                    className="h-100 d-flex justify-content-center align-items-center"
                    href={to}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon icon={icon} color="white" width="16" height="16" />
                  </a>
                </li>
              ))}
            </ul>

            <p className="text-white align-bottom mb-0">
              &copy; 2023 ZKCross. {t('all_rights_reserved')}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
});
