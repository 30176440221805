import { HTTPClient } from 'koajax';
import { toJS } from 'mobx';

import { VERCEL_URL, ZKC_SERVER_URL } from './Env';

export const isServer = () => typeof window === 'undefined';

export interface ErrorBaseData
  extends Record<
    'type' | 'title' | 'detail' | 'traceId' | 'instance' | 'message',
    string
  > {
  status: number;
}

const API_Host = isServer()
  ? VERCEL_URL
    ? `https://${VERCEL_URL}`
    : 'http://localhost:3000'
  : globalThis.location.origin;

export interface Base extends Record<'createdAt' | 'updatedAt', string> {
  id?: string | number;
}

export type PageResponse<T> = {
  count: number;
  list: T[];
};

export const ownClient = new HTTPClient({
  baseURI: `${API_Host}/api/`,
  responseType: 'json',
});

export const zkcServerClient = new HTTPClient({
  baseURI: ZKC_SERVER_URL,
  responseType: 'json',
});

export const githubClient = new HTTPClient({
  baseURI: 'https://github.com/',
  responseType: 'json',
});

export const apiClient = new HTTPClient({
  baseURI: 'https://api.github.com/',
  responseType: 'json',
}).use(async ({ request }, next) => {
  request.headers = {
    ['Authorization']: `Bearer ${globalThis.localStorage?.access_token || ''}`,
    ...request.headers,
  };

  return next();
});

export function logData(target: any, key: string, meta: PropertyDescriptor) {
  const origin: (...data: any[]) => Promise<any> = meta.value;

  meta.value = async function (...data: any[]) {
    console.log(`${key} begin Input:`, data);
    console.log(`${key} Context:`, this);
    try {
      const result = await origin.apply(this, data);
      console.log(`${key} end Output:`, toJS(result));
      return result;
    } catch (error) {
      console.log(`${key} Error:`, error);
      throw error;
    }
  };
}
