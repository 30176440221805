import '../styles/globals.less';

import { HTTPError } from 'koajax';
import { observer, useStaticRendering } from 'mobx-react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { PureComponent } from 'react';

import { Footer } from '../components/Footer';
import { MainNavigator } from '../components/MainNavigator';
import { ErrorBaseData, isServer } from '../models/Base';
import userStore from '../models/User';
import styles from '../styles/Home.module.less';

// eslint-disable-next-line react-hooks/rules-of-hooks
useStaticRendering(isServer());

globalThis.addEventListener?.('unhandledrejection', ({ reason }) => {
  const { message, body } = (reason || {}) as HTTPError<ErrorBaseData>;

  const tips = body?.detail || body?.message || message;

  if (tips) alert(tips);
});

@observer
class AppShell extends PureComponent<AppProps> {
  componentDidMount() {
    userStore.init();
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <MainNavigator />

        <div className={styles.mainComponent}>
          <Component {...pageProps} />
        </div>

        <Footer />
      </>
    );
  }
}

export default AppShell;
