import { constants } from 'ethers';
import { observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

import { i18n } from '../models/Translation';
import configStore from './Config';
import walletStore from './Wallet';
import web3Store from './Web3';

const { t } = i18n;

const { localStorage } = globalThis;

export class UserModel extends BaseModel {
  @observable
  error: string | null = null;

  @observable
  balanceInContract = constants.Zero;

  @toggle('uploading')
  async signIn() {
    try {
      await web3Store.connect();
      location.reload();
    } catch (e) {
      this.setError(t('connect_wallet_failed'));
    }
  }

  signOut() {
    localStorage.clear();
    location.reload();
  }

  @toggle('downloading')
  async init() {
    await configStore.getOne();

    const userAccount = await web3Store.getCurrentAccount();

    return userAccount && walletStore.getOne(userAccount);
  }

  setError(errorMessage: string | null) {
    this.error = errorMessage;
  }
}

export default new UserModel();
