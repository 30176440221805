export default {
  dev: 'DEV',
  account: 'Account',
  eth: 'ETH',
  connect_wallet: 'Connect Wallet',
  disconnect: 'Disconnect',
  balance: 'Balance',
  connect_wallet_to_start_the_journey: 'Connect Wallet to Start the Journey',
  connect_wallet_failed:
    'Failed to connect wallet, please check you have Metamask install correctly.',
  account_wallet_address: 'Account Wallet Address',
  apps_created: 'Apps Created',
  total_rewards: 'Total Rewards',
  claim_all: 'Claim All',
  go_to_create_description:
    'Go to Create Your First App and Start Earning ZKC Rewards.',
  go_to_create: 'Go to Create',
  my_apps: 'My Apps',
  copied: 'Copied',
  create_new_application: 'Create New Application',
  create_new_application_description:
    'Easily Migrate your app to Web3 in 3 steps',
  create_new_application_step1: 'Import Repository',
  create_new_application_step2: 'Configure App Service',
  create_new_application_step3: 'Deploy App',
  image_id: 'Image ID(MD5)',
  image_id_placeholder:
    'Select a valid wasm image file (a wasm file contains zkmain as an entry).',
  application_icon: 'Application Icon',
  application_icon_placeholder:
    'Add an icon for your application. Max size 128x128.',
  application_icon_invalid: 'Please check the file size requirement.',
  app_name: 'App Name',
  select_network: 'Select Network',
  select_network_placeholder:
    'Verification contract can still be expanded to other chains after the deployment',
  input: 'Input',
  image_description: 'Image Description',
  image_description_placeholder:
    'Enter a description and (or) a link to your application page.\nURLs starting with https:// or http:// will be parsed.',
  back_to_change: 'Back to Change',
  fees: 'Fees',
  create_new_application_image: 'Create new application image',
  deploy_verification_contract: 'Deploy verification contract',
  insufficient_balance: 'Insufficient Balance',
  top_up: 'Top Up',
  back: 'Back',
  next: 'Next',
  request_submitted: 'Request Submitted!',
  request_submitted_description:
    'It may take 5-10 minutes to complete the process, after that you can view the new app in dashboard.',
  view_on: 'View on',
  zkcross_explorer: 'ZKCross Explorer',
  back_to_dashboard: 'Back to Dashboard',
  submit: 'Submit',
  welcome_to: 'Welcome to',
  get_started_by_editing: 'Get started by editing',
  choose_file: 'Choose File',
  change: 'Change',
  total: 'Total',
  total_users: 'Total Users',
  total_requests: 'Total Requests',
  rewards_accumulated: 'Rewards Accumulated',
  chain: 'Chain',
  expand_chains: 'Expand Chains',
  claim_rewards: 'Claim Rewards',
  view_details: 'View Details',
  dashboard: 'Dashboard',
  create_app: 'Create App',
  app_ranking: 'App Ranking',
  coming_soon: 'Coming Soon',
  all_rights_reserved: 'All Rights Reserved.',
  company: 'Company',
  about_us: 'About Us',
  terms_of_service: 'Terms of Service',
  development: 'Development',
  api_documentation: 'API Documentation',
  knowledge_base: 'Knowledge Base',
  first: 'First',
  last: 'Last',
  chain_expansion: 'Chain Expansion',
  view_on_zkcross_explorer: 'View on ZKCross Explorer',
  cancel: 'Cancel',
  confirm: 'Confirm',
  connect_git_account: 'Connect Git Account',
  connect: 'Connect',
  import_repository_with_url: 'Import Repository with URL',
  or: 'Or',
  git_account: 'Git Account',
  import_repo: 'Import Repo',
  use_template: 'Use Template',
  import_repository: 'Import Repository',
  repo: 'Repository',
  branch: 'Branch',
  configure_services: 'Configure Services',
  confirm_and_create_app: 'Confirm & Create App',
  new_docker_file_created: 'New docker file created!',
  go_to_dashboard_and_download:
    'Go to Dashboard to download docker file and deploy your App.',
  go_to_dashboard: 'Go to Dashboard',
  available_templates: 'Available Templates',
  load_more: '',
  no_more: '',
} as const;
