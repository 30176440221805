import { observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

import { zkcServerClient } from './Base';

export type TaskFee = Record<
  'Create' | 'Deploy' | 'Load' | 'Settlement' | 'State' | 'VRF',
  string
>;

export interface ChainInfo
  extends Record<'chainName' | 'blockExplorerUrl' | 'deployFee', string> {
  chainId: number;
}

export interface Config {
  version: string;
  taskFee: TaskFee;
  chainInfoList: ChainInfo[];
}

export class ConfigModel extends BaseModel {
  client = zkcServerClient;
  baseURI = 'config';

  @observable config?: Config;

  @toggle('downloading')
  async getOne() {
    const { body } = await this.client.get<Config>(this.baseURI);

    this.config = body;
  }
}

export const urlConfig = {
  officialWebsite: 'https://www.zkcross.org',
  document: 'https://docs.zkcross.org',
  github: 'https://github.com/zkcrossteam',
  twitter: 'https://twitter.com/thezkcross',
  telegram: 'https://t.me/ZKCross',
  discord: 'http://discord.gg/aJNjfRvgam',
  blog: 'https://blog.zkcross.org/',
};

export default new ConfigModel();
